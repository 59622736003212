@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');

*,
*::before,
*::after {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body {
    overflow-x: hidden !important;
    -webkit-scroll-behavior: smooth;
    -moz-scroll-behavior: smooth;
    scroll-behavior: smooth;
}

a.active {
    color: #ff3f5f;
    border-bottom: 2px solid #ff3f5f;
}

a.active.no-border {
    border-bottom: none;
}

.btn-brand {
    border: 2px solid #ff3f5f;
    color: #ff3f5f;
}

.btn-brand:hover,
.powerful {
    background-color: #ff3f5f;
    color: #fff;
}

input:focus,
textarea:focus {
    outline: rgba(0,0,0,.5) solid 1px;
}

.max-w-default {
    max-width: initial !important;
    width: min(100% - 2rem, 1140px);
    margin-inline: auto;
}

.bold {
    font-weight: bold;
}

.sect-home {
    padding-top: 7rem !important;
}

.sect-about,
.sect-blog,
.get-started,
.sect-pp,
.sect-picker {
    padding-top: 5rem !important;
}

.cntc-blk {
    padding-block: 2rem !important;
}

.sect-home .main-cnt {
    width: min(100% - 2rem, 1140px);
    margin-inline: auto;
}

.my-12 {
    margin-top: 0 !important;
}

.steps,
.plans {
    display: flex;
    width: 100%;
    height: max-content;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
}

.step {
    width: 100%;
    flex-basis: 250px;
    margin: 15px;
}

.plan {
    width: 100%;
    max-width: 280px;
    margin: 10px;
    height: max-content;
    min-height: 250px;
}

.iy-rep {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.hgt-txt {
    margin-bottom: 3rem;
}

/* PRIVACY POLICY PAGE STYLING */
.pnp-hero,
.pp-cnt {
    width: min(100% - 2rem, 1146px);
    margin-inline: auto;
}

.pnp-hero {
    padding: 2rem 0 0;
    margin-bottom: 3rem;
}

.pp-cnt {
    padding-bottom: 2rem;
}

.sect-cnt {
    width: min(100%, 800px);
}

.pp-title {
    margin-bottom: 1rem;
}

.pp-title h1 {
    font-size: 1.7rem;
}

.pink {
    color: #ff3f5f;
}

.main-txt {
    padding: 1rem;
    background: #fff;
}

.main-txt h1 {
    font-size: 1.5rem;
    font-weight: bold;
    line-height: 1;
    margin-bottom: 1rem;
}

.main-txt p {
    margin-bottom: 1rem;
}

.main-txt p:not(.ql-align-justify) {
    margin: 0;
}

.main-txt ol + p:not(.ql-align-justify) {
    display: none;
}

.main-txt ol {
    list-style-type: disc;
    margin: 1.5rem 0;
    margin-left: 1rem;
}

.main-txt li {
    margin-bottom: .5rem;
}

.img-sect-wrapper,
.img-sect-content {
    height: 100%;
}

.vid-tut {
    width: 100%;
    min-height: 300px;
    height: inherit;
}


/* GET STARTED PAGE EXTRA STYLES */

.started-content {
    width: 100%;
    display: flex;
    flex-direction: column-reverse;
}

.fx-img-right {
    margin-bottom: 2rem;
    align-self: center;
    max-width: 400px;
}

.std-title {
    font-weight: bold;
    font-size: 1.5rem;
    margin-bottom: 1rem;
}

.fx-left .subtext {
    margin-bottom: 1.5rem;
    max-width: 700px;
}

.fx-left .dropdown {
    position: relative;
    padding: 10px;
    background: #fff;
    box-shadow: 5px 5px 15px rgba(0,0,0,.1),
                -1px -1px 1px rgba(0,0,0,.01);
    max-width: 200px;
    border-radius: 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2rem;
    cursor: pointer;
}

.fx-left .dropdown img {
    width: 16px;
    height: 16px;
    transition: transform .3s ease;
}

.fx-left .dropdown.active img {
    transform: rotateZ(180deg);
}

.fx-left .dropdown .droplist {
    position: absolute;
    left: 0;
    top: 100%;
    width: 100%;
    background: #fff;
    box-shadow: 5px 5px 15px rgba(0,0,0,.1);
    margin: 0;
    padding: 0;
    list-style: none;
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
    transform: translateY(10px);
    transition: all .3s ease;
}

.fx-left .dropdown.active .droplist {
    opacity: 1;
    visibility: visible;
    pointer-events: all;
    transform: translateY(0px);
}

label[for="userType"] {
    margin-bottom: 1rem;
    display: inline-block;
}

.droplist li {
    padding: 12px;
    border-bottom: 1px solid rgba(0,0,0,.05);
    cursor: pointer;
    user-select: none;
    transition: all .3s ease;
}

.droplist li:hover {
    color: #ff3f5f;
    background: rgba(0,0,0,.07);
}

.fx-left .dropdown span,
.fx-left .dropdown img {
    user-select: none;
}

.subscribe-txt {
    margin-bottom: 1rem;
}

#subscribe {
    -webkit-appearance: none;
    appearance: none;
}

label[for="subscribe"] {
    position: relative;
    user-select: none;
    display: inline-block;
    transform: translateX(1.5rem);
    margin-bottom: 1rem;
    cursor: pointer;
}

label[for="subscribe"]::before {
    content: '';
    position: absolute;
    left: -1.5rem;
    top: 50%;
    transform: translateY(-50%);
    width: 17px;
    height: 17px;
    border-radius: 3px;
    border: 1px solid #ff3f5f;
    transition: all .3s ease;
}

label[for="subscribe"]::after {
    content: '';
    position: absolute;
    left: -1.2rem;
    top: 50%;
    transform: translateY(-70%) rotate(40deg) scale(5);
    border-bottom: 2px solid #fff;
    border-right: 2px solid #fff;
    width: 7px;
    height: 10px;
    opacity: 0;
    pointer-events: none;
    transition: all .3s ease;
}

#subscribe:checked + label::before {
    background-color: #ff3f5f;
}

#subscribe:checked + label::after {
    transform: translateY(-70%) rotate(40deg) scale(1);
    opacity: 1;
}

.submit {
    display: flex;
    justify-content: center;
    margin: 2rem 0 0;
}

/* PICKER PAGE STYLES */
.sect-picker {
    min-height: 100vh;
}
.picker-content {
    width: min(100%, 1000px);
}
.picker-options {
    list-style: none;
    margin: 0;
    padding: 0;
}

.picker-title {
    font-size: 20px;
}

.w-gdrive {
    margin-bottom: 40px;
}

.g-upload-content {
    margin-top: 15px;
}

.font-norm {
    margin-bottom: 15px;
}

.step-title {
    color: #333;
    font-size: 16px;
    margin-bottom: 15px;
    align-self: flex-start;
    font-weight: bold;
}

.align-center {
    align-items: center;
}

.gdrive-upload {
    margin: 10px 0 40px;
    padding: 15px 8px;
    background: #fff;
    /* border-radius: 50%; */
    box-shadow: 5px 5px 15px rgba(0,0,0,.07),
                -1px -1px 1px rgba(0,0,0,.05);
    font-family: 'Roboto', sans-serif;
    font-size: 14px;
    font-weight: 700;
    display: flex;
    align-items: center;
    color: #333;
}

.gdrive-upload img {
    margin-right: 24px;
}

ul.temp-list {
    margin: 0;
}

.checklabel {
    display: inline-block;
    margin-left: 18px;
    position: relative;
    cursor: pointer;
}

.upload-option {
    -webkit-appearance: none;
    appearance: none;
}

.checklabel::before {
    content: '';
    position: absolute;
    left: -22px;
    top: 5px;
    width: 17px;
    height: 17px;
    border: 2px solid #ff3f5f;
    border-radius: 50%;
    transform-style: preserve-3d;
    transition: transform .3s ease;
}

.checklabel::after {
    content: '';
    position: absolute;
    left: -18.5px;
    top: 8.5px;
    transform: scale(0);
    width: 10px;
    height: 10px;
    background: #ff3f5f;
    border-radius: 50%;
    transform-style: preserve-3d;
    transition: transform .3s ease;
}

.upload-option:checked + label::after {
    transform: scale(1);
}

.upl-cnt {
    overflow: hidden;
    transition: all .3s ease-out;
}

.g-upload-content {
    max-height: initial;
    padding-bottom: 20px;
}

.local-upload {
    margin-bottom: 20px;
}

.l-upload-content {
    margin-top: 20px;
    max-height: 0px;
    opacity: 0;
}

.uploads-sect {
    max-width: 700px;
    margin-inline: auto;
}

.l-upload-content .note {
    margin-bottom: 20px;
}

.upload-blk {
    padding: 30px;
    border: 1px dashed rgba(0,0,0,.1);
    border-radius: 8px;
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    transition: all .3s ease;
}

.upload-blk.disabled {
    cursor: not-allowed;
    opacity: .5;
    pointer-events: none;
}

.upload-blk.drag-over {
    border: 2px dashed #ff3f5f;
}

.up-blk-title {
    margin-bottom: 10px;
    text-align: center;
}

.btn-upload {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 10px;
    cursor: pointer;
}

.btn-upload .fas {
    font-size: 30px;
    margin-bottom: 10px;
    color: rgba(0,0,0,.4);
    transition: color .3s ease;
}

.btn-upload span.bold {
    font-size: 16px;
    transition: color .3s ease;
}

.btn-upload:hover span.bold,
.btn-upload:hover .fas {
    color: #ff3f5f;
}

.tiny-txt {
    font-size: 12px;
    color: rgba(0,0,0,.6);
}

.l-upl-filename {
    font-size: 13px;
    display: flex;
    align-items: center;
    padding: 7px 12px;
    box-shadow: 5px 5px 10px rgba(0,0,0,.1) inset,
                -5px -5px 5px rgba(0,0,0,.1) inset;
    border-radius: 20px;
}

.l-upl-filename .cl-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    color: #fff;
    background-color: #ff3f5f;
    margin-left: 5px;
    cursor: pointer;
}

.dl-modal {
    position: fixed;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0,0,0,.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1200;
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
    transition: all .3s ease;
}

.dl-modal.open-modal {
    opacity: 1;
    visibility: visible;
    pointer-events: all;
}

.dl-modal-content {
    background: #fff;
    padding: 20px;
    border-radius: 8px;
    width: min(100% - 20px, 500px);
    margin-inline: auto;
    box-shadow: 5px 5px 25px rgba(0,0,0,.4);
    position: relative;
    transform: translateY(50px);
    opacity: 0;
    visibility: hidden;
    transition: all .5s cubic-bezier(.34,.17,.13,1.54) .2s;
}

.open-modal .dl-modal-content {
    transform: translateY(0);
    opacity: 1;
    visibility: visible;
    transition-delay: 0s;
}

.dl-title {
    font-weight: bold;
    margin-bottom: 15px;
    text-align: center;
}

a.dl {
    cursor: pointer;
    color: #ff3f5f;
    transition: color .3s ease;
}

a.dl:hover {
    color: #d75369;
    text-decoration: underline;
}

.tabs {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
}

.tab {
    padding: 0 7px;
    position: relative;
    cursor: pointer;
}

.tab::before {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    height: 2px;
    width: 100%;
    background: #ff3f5f;
    transform: scaleX(0);
    transform-origin: right;
    transition: transform .3s ease;
}

.tab.active::before {
    transform-origin: left;
    transform: scaleX(1);
}

.tab input {
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
}

.tab label {
    cursor: pointer;
    transition: color .3s ease;
}

.tab input:checked + label {
    color: #ff3f5f;
}

.close-btn {
    width: 25px;
    height: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background: #ff3f5f;
    color: #fff;
    font-weight: bold;
    position: absolute;
    top: 20px;
    right: 20px;
    cursor: pointer;
}

.dl-carousel {
    width: 100%;
    overflow-x: hidden;
}

.dl-carousel-content {
    width: 300%;
}

.dl-opt {
    float: left;
    width: calc(100% / 3);
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
}

.dl-opt .fas.fa-file-excel,
.dl-opt .fas.fa-file-csv {
    font-size: 40px;
    color: rgba(0,0,0,.4);
}

.dl-opt .icon {
    position: relative;
    margin-bottom: 30px;
}

.dl-opt .icon::before {
    content: '';
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 70px;
    height: 70px;
    background: rgba(0,0,0,.1);
    border-radius: 50%;
}

.dl-text {
    display: inline-block;
    margin: 5px 0;
    color: #d75369;
    transition: color .3s ease;
    cursor: pointer;
}

.dl-text .fas {
    margin-right: 4px;
}

.dl-text:hover {
    color: #ff3f5f;
    text-decoration: underline;
}

.form-fields .input-grid {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin: 15px 0 30px;
}

.input-grid .input-field {
    width: 100%;
    margin: 15px;
    position: relative;
    border-bottom: 1px solid rgba(0,0,0,.4);
}

.input-field input {
    width: 100%;
    height: 100%;
    padding: 10px 6px 5px;
    background: none;
    border: none;
    outline: none;
    color: #333;
    font-weight: bold;
    font-size: 15px;
}

.input-field label {
    position: absolute;
    left: 6px;
    bottom: 5px;
    z-index: 1;
    pointer-events: none;
    user-select: none;
    color: rgb(83, 83, 83);
    font-weight: bold;
    transition: all .3s ease;
}

.input-field.focused label {
    bottom: 70%;
    transform: scale(.8);
}

.last-step {
    margin-bottom: 25px;
}

button.run {
    background: #ff3f5f;
    padding: 16px 32px;
    border-radius: 5px;
    border: none;
    color: #fff;
    font-weight: bold;
    transition: background .3s ease;
    cursor: pointer;
}

button.run:hover,
button.run:active {
    background: #d75369;
}

.is-running {
    width: min(100%, 500px);
    margin-inline: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.is-running h2 {
    margin: 50px 0 80px;
    width: 100%;
    text-align: center;
}

.done-analyzing {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.done-analyzing h2 {
    font-weight: bold;
    margin-bottom: 20px;
    font-size: 20px;
    text-align: center;
}

.done-analyzing p {
    text-align: center;
    margin-bottom: 30px;
}

.done-btn-group {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.done-btn-group button {
    margin: 10px 15px;
    padding: 15px;
    border: none;
    border-radius: 8px;
    color: #fff;
}

.done-btn-group button.success {
    background: rgb(7, 141, 34);
}

.done-btn-group button.continue {
    background: #ff3f5f;
}

/* REPORT PAGE STYLING */
.load-container {
    width: 100%;
    height: min(87vh, 645px);
    display: flex;
    justify-content: center;
    align-items: center;
}

.report,
.report-content {
    width: 100%;
    height: max-content;
}

.header-bar {
    width: 100%;
    padding: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #fff;
    background-image: linear-gradient(to top right, #fc260099, #ff3f5f);
    position: relative;
    border-radius: 0px 0px 40px 40px;
    box-shadow: 5px 5px 10px #fc260099;
    margin-bottom: 40px;
}

.header-bar::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-image: url(https://picsum.photos/1920/1080?random);
    background-size: cover;
    border-radius: 0px 0px 40px 40px;
    z-index: -1;
}

.header-text {
    text-align: center;
    margin-bottom: 15px;
}

.bold {
    font-weight: bold;
}

.header-text h1 {
    font-size: 22px;
    font-weight: bold;
}

.header-text h3 {
    font-size: 18px;
    font-weight: bold;
}

.hd-action {
    font-size: 16px;
    display: inline-block;
    margin: 0 10px;
    cursor: pointer;
}

.hd-action:first-of-type .fas {
    margin-right: 5px;
}

.summary-content {
    width: min(100% - 20px, 1100px);
    margin: 0 auto;
}

.meta {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 80px;
}

.report .school,
.report .subject {
    font-size: 20px;
}

.meta-txt {
    font-size: 16px;
}

.date .fas {
    margin-right: 6px;
}

.max-min {
    width: 100%;
    height: max-content;
    margin-block: 30px;
}

.min-max-flex {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: min(100%, 1100px);
    margin-inline: auto;
}

.minmax-blk {
    display: flex;
    justify-content: space-between;
    padding: 20px;
    width: min(100%, 550px);
    align-items: center;
    border-radius: 5px;
    box-shadow: 5px 5px 15px rgba(0,0,0,.1);
}

.max {
    background: #385F73;
    margin-bottom: 20px;
}

.min {
    background: #ff3f5f;
}

.minmax-txt {
    margin-right: 25px;
}

.minmax-txt h4 {
    color: #fff;
    font-size: 16px;
}

.minmax-txt p {
    color: hsla(0,0%,100%,.7);
}

.score {
    font-weight: bold;
    font-size: 35px;
    color: #fff;
}

.sect-title {
    width: 100%;
    height: max-content;
    margin-block: 50px;
}

.sect-title h1 {
    font-weight: bold;
    font-size: 22px;
    margin-bottom: 15px;
    text-align: center;
}

.missed-ques {
    padding: 10px;
    display: flex;
    flex-direction: column;
    color: #fff;
    background: #ff3f5f;
    border-radius: 5px;
    box-shadow: 5px 5px 15px rgba(0,0,0,.1);
    margin-bottom: 15px;
    margin-inline: auto;
    max-width: 800px;
}

.pct {
    font-size: 18px;
    align-self: flex-end;
    margin-top: 5px;
}

.foot-note {
    width: 100%;
    height: max-content;
    padding: 15px 0;
    background: #e2e2e2;
}

.foot-content {
    width: min(100% - 20px, 1000px);
    margin-inline: auto;
    font-size: 14px;
    text-align: center;
}

.info {
    width: min(80%, 700px);
    margin-inline: auto;
    text-align: center;
    display: flex;
    align-items: center;
    font-size: 11px;
    margin-bottom: 25px;
    padding: 10px 15px;
    border-radius: 8px;
    background-color: rgb(229, 246, 253);
    color: rgb(1, 67, 97);
}

.info.mt {
    margin-top: 25px;
}

.info .fas {
    margin-right: 10px;
    font-size: 16px;
}

.banner {
    width: min(100% - 20px, 1100px);
    margin: 0 auto 70px;
    border: 1px solid rgba(0,0,0,.2);
    /* display: none; */
}

.banner-content {
    padding: 15px;
    display: flex;
    justify-content: space-between;
}

.banner .img-wrapper {
    width: min(100%, 50px);
    height: 50px;
    align-self: center;
}

.banner .img-wrapper img {
    width: 100%;
    height: 100%;
}

.banner-text {
    text-align: right;
    font-weight: bold;
}

.banner-text h1,
.banner-text h3 {
    text-transform: uppercase;
}

@media screen and (min-width: 600px) {
    .form-fields .input-grid {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-column-gap: 20px;
        margin: 30px 0 50px;
    }

    .input-grid .input-field {
        width: initial;
        margin: 15px 0;
    }

    .done-btn-group {
        flex-direction: row;
    }

    .banner {
        margin-bottom: 70px;
    }

    .banner .img-wrapper {
        width: min(100%, 80px);
        height: 80px;
    }

    .banner-text h1 {
        font-size: 25px;
    }

    .banner-text h3 {
        font-size: 18px;
    }
}

@media screen and (min-width: 768px) {
    .sect-home,
    .sect-about,
    .sect-blog,
    .get-started,
    .sect-pp,
    .sect-picker {
        padding-top: 10rem !important;
    }

    .sect-title {
        margin-block: 100px;
    }

    .iy-rep {
        flex-direction: row;
    }

    .hgt-txt {
        margin-right: 2rem;
    }

    .pp-title h1 {
        font-size: 2rem;
    }

    .main-txt {
        padding: 2rem;
    }

    .main-txt h1 {
        font-size: 1.7rem;
    }
    
    .main-txt p {
        font-size: 1rem;
    }

    .started-content {
        flex-direction: row;
    }

    .fx-img-right {
        max-width: initial;
        margin-left: 2rem;
        align-self: flex-start;
    }

    .submit {
        justify-content: flex-start;
    }

    .step-title {
        font-size: 20px;
    }

    .font-norm {
        font-size: 18px;
    }

    .w-gdrive {
        margin-bottom: 40px;
    }

    .upload-blk {
        padding: 40px;
    }

    .upload-blk:first-of-type {
        margin-bottom: 40px;
    }

    .btn-upload .fas {
        font-size: 37px;
    }

    .btn-upload {
        margin-bottom: 15px;
    }

    .btn-upload span.bold {
        font-size: 20px;
    }

    .up-blk-title {
        font-size: 20px;
    }

    .tiny-txt,
    .filename {
        font-size: 14px;
    }

    .dl-title {
        font-size: 20px;
    }

    .done-analyzing h2 {
        font-size: 25px;
    }

    .done-btn-group button {
        padding: 15px 25px;
    }

    .header-bar {
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-start;
        padding: 15px 5%;
    }

    .header-actions {
        align-self: flex-end;
    }

    .header-text {
        text-align: initial;
        margin-bottom: 0;
    }

    .header-text h1,
    .header-text h3 {
        font-size: 20px;
    }

    .banner .img-wrapper {
        width: min(100%, 100px);
        height: 100px;
    }

    .banner-text h1 {
        font-size: 30px;
    }

    .banner-text h3 {
        font-size: 20px;
    }

    .report .school,
    .report .subject {
        font-size: 30px;
    }

    .meta-txt {
        font-size: 18px;
    }

    .min-max-flex {
        flex-direction: row;
        justify-content: space-between;
    }

    .max {
        margin-bottom: 0;
        margin-right: 30px;
    }

    .score {
        font-size: 50px;
    }

    .minmax-txt h4 {
        font-size: 18px;
    }

    .missed-ques {
        flex-direction: row;
        justify-content: space-between;
    }

    .ques {
        margin-right: 20px;
    }

    .pct {
        margin-top: 0;
        white-space: nowrap;
    }

    .info {
        text-align: left;
        font-size: 12px;
    }
}

@media screen and (min-width: 1200px) {
    .steps {
        justify-content: space-between;
    }

    .iy-rep {
        justify-content: center;
    }

    .hgt-txt {
        margin-right: 5rem;
    }

    .plan {
        margin: 0;
        padding: 32px 20px;
    }

    .plan > div {
        width: 100%;
        height: max-content;
    }

    .pln-btn {
        position: relative;
        left: 50%;
        bottom: -30px;
        transform: translateX(-50%);
        margin-top: 0;
    }

    .pp-title h1 {
        font-size: 2.3rem;
    }

    .started-content {
        margin-bottom: 5rem;
    }

    .std-title {
        font-size: 2.3rem;
    }

    .form-fields .input-grid {
        grid-template-columns:  repeat(3, 1fr);
    }

    .banner-text h1 {
        font-size: 38px;
    }

    .banner-text h3 {
        font-size: 24px;
    }

    .report .school,
    .report .subject {
        font-size: 40px;
    }

    .meta-txt {
        font-size: 20px;
    }

    .foot-content {
        font-size: 15px;
    }

    .vid-tut {
        height: 370px;
    }
}