.temp-list {
    list-style: none;
    width: 100%;
    height: max-content;
    padding: 15px;
    position: relative;
}

.attachment {
    width: min(100%, 800px);
    box-shadow: 5px 5px 15px rgba(0,0,0,.07),
                -1px -1px 5px rgba(0,0,0,.01);
    border-radius: 5px;
}

.attachment-item {
    display: flex;
    align-items: center;
}

.attachment-item img {
    width: 25px;
    height: 25px;
    margin-right: 10px;
}

.close {
    background: none;
    border: 1px solid rgba(0,0,0,.08);
    border-radius: 50%;
    width: 26px;
    height: 26px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 50%;
    right: 4%;
    transform: translateY(-50%);
}