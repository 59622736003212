.indicator {
    width: 70%;
    max-width: 100px;
    opacity: 0;
    visibility: hidden;
}

.indicator.show {
    opacity: 1;
    visibility: visible;
}

.loading {
    display: flex;
    justify-content: center;
}

.circle {
    background-color: #ff3f5f;
    border-radius: 50%;
    transform-style: preserve-3d;
    animation: jump .5s ease-in infinite;

}

.circle:nth-of-type(2) {
    margin: 0 10px;
    animation-delay: .1s;
}

.circle:last-of-type {
    animation-delay: .2s;
}

@keyframes jump {
    0% {
        transform: translateY(0);
    }

    50% {
        transform: translateY(-30px);
    }
}