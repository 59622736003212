.main-tb-container {
    width: 100%;
    height: max-content;
    margin-inline: auto;
    padding: 0px 5px 0px;
    border-radius: 8px;
    box-shadow: 5px 5px 25px rgba(0,0,0,.1);
}

.table-wrapper {
    height: 100%;
    width: 100%;
    overflow-x: auto;
    margin-bottom: 8px;
}

.table {
    height: max-content;
    width: max-content;
}

th {
    color: #3c3c3c;
    text-align: left;
    white-space: nowrap;
    cursor: pointer;
}

th, td {
    padding: 10px;
    font-size: 14px;
}

tr {
    border-bottom: 1px solid rgba(0,0,0,.1);
    white-space: nowrap;
}

.sort-ctrls {
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin-left: 6px;
    width: 15px;
    height: 5px;
}

.sort-ctrls .fas {
    height: 40%;
    transition: color .3s ease;
}

th[data-sort="asc"] .fas.fa-sort-up,
th[data-sort="desc"] .fas.fa-sort-down {
    color: #ff3f5f;
}

.tb-controls {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 10px 0;
    font-weight: 300;
    font-size: 12px;
}

.rpp {
    display: flex;
    align-items: center;
}

.rpp select {
    margin-left: 8px;
    border-bottom: 1px solid rgba(0,0,0,.1);
    outline: none;
}

.rpp,
.count-indicator,
.pg-controls {
    margin-right: 20px;
}

.pg-controls button:first-of-type {
    margin-right: 15px;
}

.pg-controls button {
    cursor: pointer;
}


@media screen and (min-width: 768px) {
    .table {
        width: 100%;
    }
}